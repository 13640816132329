<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col cols="12" sm="12" md="8" lg="6">

                <v-form>
                    <v-text-field label="Leistungserbringer" v-model="leistungserbringer"
                                  maxlength="9" inputmode="numeric" pattern="[0-9]"
                                  @keydown="$_onKeydown" @paste="$_onPaste"
                                  hint="Bitte geben Sie ein Institutionskennzeichen ein"
                                  class="d-inline-flex" autocomplete="off"
                                  required clearable validate-on-blur />


                    <v-btn color="primary" type="submit" class="d-block mt-4"
                           :disabled="!leistungserbringer || leistungserbringer.length < 9 || loading"
                           :loading="loading" @click.stop.prevent="$_fetchLEData">

                        {{ buttonLabel }}
                    </v-btn>

                    <v-alert border="left" type="error" v-if="errorMessage" class="mt-4">
                        {{ errorMessage }}
                    </v-alert>
                </v-form>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {

        name: 'SwitchIK',

        props: {
            buttonLabel: String,
        },

        data: () => ({
            leistungserbringer: '',
            errorMessage: '',
            loading: false,
        }),

        activated() {

            this.initialize();
        },
        methods: {

            initialize() {
                this.leistungserbringer = '';
                this.errorMessage = '';
                this.loading = false;
            },

            $_onKeydown: function (event) {

                //Backspace, Enter, Left/ArrowLeft, Right/ArrowRight, Ctrl
                if ([8, 13, 37, 39].includes(event.which) || event.ctrlKey)
                    return;

                // 0-9 (general keys or numpad)
                if (/[0-9]/.test(event.key))
                    return;

                event.preventDefault();
            },

            $_onPaste: function (event) {

                var str = event.clipboardData.getData('text/plain');
                str = str?.replace(/\s/g, '');

                if (!str || parseInt(str) != str)
                    event.preventDefault();
            },

            $_fetchLEData: function () {

                this.loading = true;
                this.$http.get('auth/switchIK', { params: { leistungserbringer: this.leistungserbringer } })
                    .then((response) => {
                        if (response.status === 200 && response.data) {

                            // Antwort aus beiden Objekten zusammenstellen und in store schreiben
                            let rsp = Object.assign({}, this.$store.state.currentUser, response.data);

                            // Falls auf das Spezial-IK gewechselt wurde, wird Preview-Funktionalität freigeschaltet
                            rsp.canPreviewFunctions = (this.leistungserbringer === '999666333');
                            this.$store.commit('updateCurrentUser', rsp);

                            this.$emit('switchDone');
                            this.loading = false;
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.errorMessage = error.response.data.message;
                            this.loading = false;
                        }
                    }).finally(() => {
                        // Falls auf das Spezial-IK gewechselt wurde, wird Preview-Funktionalität freigeschaltet
                        if (this.leistungserbringer === '999666333') {
                            let rsp = this.$store.state.currentUser;
                            rsp.canPreviewFunctions = true;
                            this.$store.commit('updateCurrentUser', rsp);
                            console.log('Preview functions enabled');
                        }
                    });
            },
        },
    }
</script>