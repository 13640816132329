<template>
    <v-dialog v-model="openDialog" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
        <v-card>
            <v-card-title class="text-h7 text-md-h6 flex-nowrap align-start">
                <v-icon color="primary" large left>mdi-information-outline</v-icon>
                Wichtige Anpassungen für das Service-Portal für Apotheken
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-body-1 text--primary" Scrollable>
                <div v-if="mitteilungId ">
                    <p class="text-justify text-hyphenate mt-6 ml-0">Sehr geehrtes Apotheker-Team, </p>
                    <p class="text-justify text-hyphenate ml-0">
                        aufgrund Ihrer zahlreichen, positiven und konstruktiven Rückmeldungen zu unserem Service-Portal für Apotheken konnten wir dieses für Sie weiter optimieren. Ihre Wünsche und Anregungen haben uns angespornt, unser Portal noch attraktiver für Sie zu gestalten.
                        <br /> Mit dem heutigen Update möchten wir Sie einladen, unser Portal neu zu entdecken. Informieren Sie sich auf den folgenden Seiten gern über die einzelnen Neuerungen und probieren Sie diese aus.
                    </p>
                    <p class="text-justify text-hyphenate mb-2 ml-0">  Weiterhin viel Erfolg mit unserem Service-Portal für Apotheken!</p>

                    <!--<p class="text-justify text-hyphenate mb-0 ml-2">
                        <span v-html="this.infoMessage"></span>
                    </p>-->
                    <v-carousel :continuous="false"
                                :height="height"
                                hide-delimiter-background
                                flat style="background-color: #e7e01e; "
                                show-arrows-on-hover>
                       
                        <v-carousel-item v-for="(slide, i) in infoMessage"
                                         :key="i">
                            <v-sheet class="sheetImg overflow-x-hidden "
                                     height="100%"
                                     v-resize="onResize">
                                <v-row class="fill-height"
                                       justify="center">
                                    <div class="fill-height mx-4">
                                        <p class="text-justify text-hyphenate mt-6 ml-2">
                                            <b>
                                                <span v-html="slide.titel"></span>
                                            </b>
                                        </p>
                                        <p class="text-justify text-hyphenate mt-6 ml-2"><span v-html="slide.text"></span></p>
                                    </div>
                                </v-row>
                            </v-sheet>
                        </v-carousel-item>
                    </v-carousel>
                </div>
                <!--Test-->
                <!-- Falls eine AGB zur Bestätigung gibt, dann diesen Teil ohne fixed Footer anzeigen -->
                <span v-if="nutzungsbedingungId">
                    <strong>
                        <p class="text-justify text-hyphenate ml-0 mt-2">
                            Bevor Sie die neuen Funktionen nutzen, lesen Sie bitte die neuen
                            <router-link to="/terms" target="_blank">
                                allgemeine Nutzungsbedingungen
                            </router-link>
                            sorgfältig duch und bestätigen Sie, dass Sie damit einverstanden sind.
                        </p>
                    </strong>
                    <v-row dense class="mt-n2 ml-n3">
                        <v-col cols="auto">
                            <v-checkbox v-model="agreeAGB" class="mt-0 pt-0 ml-1" hide-details />
                        </v-col>
                        <v-col>
                            <p class="ml-n2">
                                Ich bestätige, die Benutzungsbedingungen des GFS-Service-Portals
                                gelesen zu haben und bin mit deren Geltung einverstanden.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="justify-end">
                        <v-btn color="primary" type="submit"
                               @click.stop.prevent="doCancel" class="mr-2 mb-4">
                            Abbrechen
                        </v-btn>
                        <v-btn color="primary" type="submit"
                               :disabled="!skipInfoBox && !agreeAGB"
                               @click.stop.prevent="onDialogClose" class=" mb-4">
                            Speichern
                        </v-btn>
                    </v-row>

                </span>
            </v-card-text>
            <!-- Falls eine Mitteilung gibt, dann diesen Teil mit fixed Footer anzeigen -->
            <span class="mt-0" v-if="mitteilungId && !nutzungsbedingungId">
                <v-divider></v-divider>
                <v-card-actions>

                    <v-row v-if="mitteilungId " dense class="mt-n2">
                        <v-col cols="mr-0">
                            <v-checkbox v-model="skipInfoBox" label="Diese Nachricht nicht mehr anzeigen" class="mt-0 pt-0 ml-1" hide-details />
                        </v-col>
                    </v-row>
                    <v-spacer />
                    <v-btn color="primary" type="submit"
                           @click.stop.prevent="doCancel" class="mr-2">
                        Abbrechen
                    </v-btn>
                    <v-btn color="primary" type="submit"
                           :disabled="!skipInfoBox && !agreeAGB"
                           @click.stop.prevent="onDialogClose">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </span>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'DialogMitteilungen',
        props: {
            showDialog: false,
            infoData: {},
            agbDaten: {}
        },
        data: function () {
            return {
                skipInfoBox: false,
                agreeAGB: false,

                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4',
                ],
            }
        },

        computed: {

            height() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return 220
                    case 'sm': return 800
                    case 'md': return 800
                    case 'lg': return 400
                    case 'xl': return 300
                }
            },

            openDialog: {
                get() {
                    return this.showDialog;
                },

                set(value) { 
                    return value;
                }
            },

            infoMessage() {
                //console.log('this.infoData ', this.infoData);
                //this.mitteilungId = this.infoData[0].mitteilung;
                return this.infoData;
             //   return this.infoData[0].text;
            },

            mitteilungId: {
                get() {
                    return this.infoData[0].mitteilung;
                },

                set(value) {
                    return value;
                }
            },

            nutzungsbedingungId: {
                get() {
                    return this.agbDaten[0].nutzungsbedingung; 
                },

                set(value) {
                    return value;
                } 
                
            }
        },

        methods: {
            onResize() {
                this.windowSize = { x: window.innerWidth, y: window.innerHeight }
            },

            async onDialogClose() {

                if (this.agreeAGB) {
                    console.log('this.agreeAGB', this.agreeAGB);

                    await this.$http.post('mitteilung/nutzungsbedingung', {
                        Benutzer: this.$store.state.currentUser.benutzer,
                        Nutzungsbedingung: this.nutzungsbedingungId
                    }).then(response => {
                        if (response.status === 200) {
                            this.nutzungsbedingungId = null;
                            this.agreeAGB = false;
                            if (this.mitteilungId) this.skipMitteilung();
                        }
                    });
                }

                if (this.skipInfoBox) {
                    //console.log('this.skipInfoBox', this.skipInfoBox);
                    await this.skipMitteilung();
                }
                this.$emit('ShowInfoDialog', false)
            },
            doCancel() {
                this.$emit('ShowInfoDialog', false);
            },

            skipMitteilung() {
                this.$http.post('mitteilung', {
                    Benutzer: this.$store.state.currentUser.benutzer,
                    Mitteilung: this.mitteilungId
                }).then(response => {

                    if (response.status === 200) {
                        this.mitteilungId = null;
                        this.skipInfoBox = false;
                    }
                });
            }
        },
    }
</script>
<style scoped>
    .sheetImg {
        /*background: url("../assets/gfs-logo-512.png") no-repeat center center;*/
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .resizable {
        resize: Vertical;
        overflow-x: hidden;
        /*overflow: auto;*/
        border: 1px solid black;
    }
  
    >>> .v-dialog:not(.v-dialog--fullscreen) {
        max-width: 51%;
        flex: 0 0 51%;
    }

   
</style>
