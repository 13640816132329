import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        // persistent, �berlebt browser-refresh
        // localStorage: unabh�ngig von Tabs, bleibt nach Schlie�en von Tab / Browser bestehen
        // sessionStorage: nur f�r aktuellen Tab, geht nach Schlie�en von Tab / Browser verloren
        currentUser: null,
        cookieConsent: false,
        cookieConsentAsk: true,

        // dynamisch, �berlebt keinen browser-refresh
        selectedAbsetzungen: [],
        BMonate: null,
        defCache: null,
        leseBestaetigungWarnung: null,
        anrede: null,
        module: {
            retaxRead: false,
            retaxWrite: false,
            zuzahlungRead: false,
            zzRueckforderungRead: false,
            zzRueckforderungWrite: false
        },

        // wird nicht ver�ndert
        // FIXME: Nur tempor�r wg. DEV-1709, danach zur�ckbauen!
        //serviceMessage:
        //    'Aktuell stehen Ihnen keine Absetzungsinformationen zur Bearbeitung zur Verf&uuml;gung. ' +
        //    'Sobald Sie Ihre Daten einsehen k&ouml;nnen, erhalten Sie eine Benachrichtigung an die bei der Registrierung hinterlegte E-Mail-Adresse.',
    },

    mutations: {
        initializeStore: function (state) {
            state.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            state.cookieConsentAsk = sessionStorage.getItem('cookiesDeclined') !== String(true);

            let cookiesAccepted = moment(localStorage.getItem('cookiesAccepted'));
            state.cookieConsent = cookiesAccepted.isValid() && moment() < cookiesAccepted.add(moment.duration(6, 'M'));
        },

        updateCurrentUser: function (state, value) {
            if (value) {
                state.currentUser = value;
                sessionStorage.setItem('currentUser', JSON.stringify(value));
            }
            else {
                state.currentUser = null;
                sessionStorage.removeItem('currentUser');
            }
        },

        updateCookieConsent: function (state, value) {
            if (value) {
                state.cookieConsent = true;
                state.cookieConsentAsk = false;
                localStorage.setItem('cookiesAccepted', moment().format());
                sessionStorage.removeItem('cookiesDeclined');
            }
            else {
                state.cookieConsent = false;
                state.cookieConsentAsk = false;
                localStorage.removeItem('cookiesAccepted');
                sessionStorage.setItem('cookiesDeclined', String(true));
            }
        },

        updateDefCache: function (state, { field, value }) {
            if (!state.defCache)
                state.defCache = [];

            state.defCache[field] = value;
        },

        updateSelectedAbsetzungen: function (state, value) {
            state.selectedAbsetzungen = value;
        },

        updateBMonate: function (state, value) {
            state.BMonate = value;
        },

        updateLeseBestaetigungWarnung: function (state, value) {
            state.leseBestaetigungWarnung = value;
        },

        updateCurrentError: function (state, value) {
            state.currentError = value;
        },

        updateModule: function (state, value) {
            state.module = Object.assign({}, value);
        },

        updateAnrede: function (state, value) {
            state.anrede = value;
        },
    }
});

export default store;