<template>
    <v-app id="serviceportal">

        <!--
            App-Bar Höhe:
            Breakpoint                          Normal      Shrink
            xs (Handy hochkant)                 56px        --
            sm (Handy quer oder "Phablet")      96px        56px
            md (Tablet, kl. Convertables)       112px       56px
            lg (Desktop)                        128px       --
            xl (Heimkino)                       128px       --
        -->
        <!-- App bar -->
        <v-app-bar app class="white text-h4 text-md-h3 text-lg-h2" ref="header"
                   min-height="56px" :prominent="$vuetify.breakpoint.smAndUp"
                   :dense="$vuetify.breakpoint.smAndDown" :short="$vuetify.breakpoint.mdOnly"
                   :shrink-on-scroll="$vuetify.breakpoint.smOnly || $vuetify.breakpoint.mdOnly"
                   clipped-left>

            <v-app-bar-nav-icon id="appBarNavIcon" class="d-flex d-lg-none align-center ml-0 pa-2 pl-3"
                                width="auto" height="100%" :ripple="false" :disabled="!isUserLoggedIn"
                                @click="toggleNavigationDrawer" tile>

                <v-icon class="ml-1 mr-2">
                    mdi-menu
                </v-icon>
                <v-img src="./assets/gfs-logo-512.png"
                       max-height="40px" min-width="80px" max-width="15vw" contain />

            </v-app-bar-nav-icon>

            <div class="d-none d-lg-flex fill-height justify-end mr-3 overflow-x-hidden">
                <v-img src="./assets/GFS_Pixelwolke_gelb_links_transparent.png"
                       max-height="128px" max-width="416px" position="left center" contain />

            </div>

            <div class="d-flex flex-column fill-height flex-grow-1 overflow-x-hidden">
                <div class="my-auto px-2 overflow-y-hidden">
                    <div class="d-flex justify-center align-center" style="min-height: 56px !important;">
                        <span class="font-weight-semibold primary--text text-center text-no-wrap">
                            Service-Portal
                            <span class="d-none d-sm-inline">
                                f&uuml;r Apotheken
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="d-none d-lg-flex fill-height justify-start ml-3 overflow-x-hidden">
                <v-img src="./assets/GFS_Pixelwolke_gelb_rechts_transparent.png"
                       max-height="128px" max-width="416px" position="right center" contain />

            </div>
        </v-app-bar>

        <v-navigation-drawer app v-if="isUserLoggedIn" v-model="showNavigationDrawer"
                             :clipped="$vuetify.breakpoint.smAndUp"
                             :mini-variant="minimizeNavigationDrawerForBreakpoint"
                             :permanent="$vuetify.breakpoint.mdAndUp"
                             :touchless="$vuetify.breakpoint.mdAndUp"
                             :width="$vuetify.breakpoint.xl ? 280 : 256">

            <v-list nav class="pt-4 fill-height d-flex flex-column">
                <v-list-item-group v-model="navItemSelected" color="primary">
                    <v-list-item to="/" two-line>
                        <v-list-item-icon class="my-6 mr-4">
                            <v-icon :large="$vuetify.breakpoint.lgAndUp">
                                mdi-account-circle
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 font-weight-semibold">
                                {{ $store.state.currentUser.apoName }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-2">
                                {{ $store.state.currentUser.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mb-2" />
                    <template v-for="(item, i) in navItemList">
                        <v-divider v-if="!item"
                                   color="primary"
                                   class="dividerClass"
                                   :key="`divider-${i}`"></v-divider>

                        <v-list-item v-else :key="item.title" :to="item.route" :disabled="item.disabled">
                            <v-list-item-icon class="mr-4">
                                <v-icon :large="$vuetify.breakpoint.lgAndUp">
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6 font-weight-semibold">
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-item-group>
                <v-spacer />
                <v-divider class="my-2" />
                <v-list-item-group>
                    <v-list-item @click.stop="navLogout.method" :ripple="false" two-line inactive>
                        <v-list-item-icon class="my-6 mr-4">
                            <v-icon :large="$vuetify.breakpoint.lgAndUp">
                                {{ navLogout.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 font-weight-semibold">
                                {{ navLogout.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-2">
                                <v-tooltip right fixed>
                                    <template v-slot:activator="{ on }">
                                        <p v-on="on">Abmeldung in  {{ timeToLogoutText }}</p>
                                    </template>
                                    <span>Abmeldung in {{ timeToLogoutText }}</span>
                                </v-tooltip>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="!supportUser">
                            <v-btn icon @click.stop="resetLogoutTimer" v-if="$store.state.cookieConsent">
                                <v-icon>$vuetify.icons.renew</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <!--<v-list-item v-if="isDevelopment" @click.stop="deactivateLogoutTimer" two-line dense>
                        <v-list-item-icon class="my-6 mr-4">
                            <v-icon :large="$vuetify.breakpoint.lgAndUp">
                                $vuetify.icons.stop
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 font-weight-semibold">
                                Entwicklung
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-subtitle-2">
                                Timer deaktivieren
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>-->
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <!-- Main content panel -->
        <v-main>
            <!--
                min-width von 320px wird empfohlen, kleinster gebräuchlicher viewport IPhone 5:
                https://uxpickle.com/what-is-the-smallest-screen-size-you-should-design-for/
                https://ux.stackexchange.com/questions/53911/what-is-the-smallest-screen-size-you-design-for
            -->
            <v-responsive min-width="320px" class="fill-height pa-4">

                <!-- Incompatible browser -->
                <v-row v-if="isIE" class="justify-center">
                    <v-col cols="12" sm="8" md="6">
                        <v-alert border="left" type="error">
                            Der von Ihnen verwendete Browser ist veraltet und unterstützt nicht alle Funktionen des Service-Portals für Apotheken.
                            Bitte wechseln Sie zu einem aktuellen Browser (z.B. Chrome, Edge, Firefox in der jeweils aktuellsten Version).
                        </v-alert>
                    </v-col>
                </v-row>

                <!-- Session expired alert-->
                <v-row v-else-if="sessionExpired" class="justify-center">
                    <v-col cols="auto">
                        <v-alert prominent border="left" type="error">
                            <v-row class="align-center justify-center">
                                <v-col cols="auto" class="text-center">
                                    Ihre Sitzung ist abgelaufen.
                                    Sie wurden automatisch abgemeldet.
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn outlined @click="sessionExpired = false;">
                                        Schließen
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>

                <!-- Main router view -->
                <keep-alive v-else>
                    <router-view />
                    <!--<benutzer-anmeldung />-->
                </keep-alive>

            </v-responsive>
        </v-main>

        <!-- Footer -->
        <v-footer app :absolute="$vuetify.breakpoint.smAndDown" class="text-body-2 pa-1" ref="footer">
            <v-row class="extra-dense align-center">
                <v-col cols="12" class="mr-n3 pa-0" v-show="$route.name === 'login' && $vuetify.breakpoint.smAndUp">
                    <div id="recaptcha-container" />
                </v-col>
                <v-col cols="auto" class="flex-grow-1 flex-md-grow-0">
                    <div class="kassenlogo">
                        <v-img src="./assets/Logo_BARMER_1000x700.png" class="mx-auto"
                               max-height="80px" min-width="60px" max-width="8vw" contain />
                    </div>
                </v-col>
                <v-col cols="auto" class="flex-grow-1 flex-md-grow-0">
                    <div class="kassenlogo">
                        <v-img src="./assets/Logo_DAK_1000x700.png" class="mx-auto"
                               max-height="80px" min-width="60px" max-width="8vw" contain />
                    </div>
                </v-col>
                <v-col cols="auto" class="flex-grow-1 flex-md-grow-0">
                    <div class="kassenlogo">
                        <v-img src="./assets/Logo_HEK_1000x700.png" class="mx-auto"
                               max-height="80px" min-width="60px" max-width="8vw" contain />
                    </div>
                </v-col>
                <v-col cols="auto" class="flex-grow-1 flex-md-grow-0">
                    <div class="kassenlogo">
                        <v-img src="./assets/Logo_MKK_1000x700.png" class="mx-auto"
                               max-height="80px" min-width="60px" max-width="8vw" contain />
                    </div>
                </v-col>
                <v-col cols="auto" class="flex-grow-1 flex-md-grow-0">
                    <div class="kassenlogo">
                        <v-img src="./assets/Logo_KKH_1000x700.png" class="mx-auto"
                               max-height="80px" min-width="60px" max-width="8vw" contain />
                    </div>
                </v-col>
                <v-col cols="auto" class="flex-grow-1">
                    <v-row dense class="justify-end align-center flex-wrap-reverse">
                        <v-col>
                            <v-row class="extra-dense justify-end">
                                <v-col class="text-end">
                                    Service-Portal f&uuml;r Apotheken 2024.3.1 &copy;&nbsp;
                                    <a href="http://www.gfs-web.de" target="_blank">
                                        GFS&nbsp;GmbH
                                    </a>
                                </v-col>
                            </v-row>
                            <v-row class="extra-dense justify-end flex-nowrap">
                                <v-col cols="auto">
                                    <router-link to="/faq" target="_blank">
                                        FAQ
                                    </router-link>
                                </v-col>
                                <v-col cols="auto">
                                    <router-link to="/kontakt">
                                        Kontakt
                                    </router-link>
                                </v-col>
                                <!--<v-col cols="auto">
                                    <a href="https://termingfs.simplybook.it/v2/" target="_blank">
                                        Rückruftermin buchen
                                    </a>
                                </v-col>-->
                                <v-col cols="auto">
                                    <router-link to="/datenschutz" target="_blank">
                                        Datenschutz
                                    </router-link>
                                </v-col>
                                <v-col cols="auto">
                                    <router-link to="/terms" target="_blank">
                                        Benutzungsbedingungen
                                    </router-link>
                                </v-col>
                                <v-col cols="auto">
                                    <a href="http://www.gfs-web.de/impressum/" target="_blank">
                                        Impressum
                                    </a>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-img src="./assets/Logo_GFS_1000x700.png" class="mx-auto"
                                   max-height="80px" min-width="80px" max-width="8vw" contain />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-footer>

        <v-dialog v-model="showWaitDialog" persistent>
            <v-card>
                <v-card-title class="text-h5 text-md-h4 flex-nowrap align-start">
                    <v-icon color="primary" large left>mdi-timer-sand</v-icon>
                    Einen Moment bitte...
                </v-card-title>
                <v-card-text class="text-body-1 text--primary">
                    <v-progress-linear indeterminate />
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-app>
</template>

<script>
    import eventBus from '@/main';
    import * as workerTimers from 'worker-timers';
    //    import BenutzerAnmeldung from '@/components/BenutzerAnmeldung.vue';

    export default {
        /*
                components: {
                    BenutzerAnmeldung
                },

        */
        data() {
            return {
                showNavigationDrawer: false,
                minimizeNavigationDrawer: true,
                timeToLogout: null,
                intervalId: null,
                sessionExpired: false,
                navItemSelected: 0,
                navLogout: { title: 'Abmelden', icon: '$vuetify.icons.logout', method: this.logout },
                showWaitDialog: false,
                resizeObserver: null
            }
        },

        created() {
            eventBus.$on('resetLogoutTimer', this.resetLogoutTimer);
            eventBus.$on('loginSuccessful', () => this.execSync(this.refreshStoreData));
            eventBus.$on('logout', this.logout);
            eventBus.$on('moduleSelected', this.getModule); 
            eventBus.$on('startWidget', this.startWidget); 

        },

        computed: {

            navItemList() {
                var items = [
                    { title: 'Beanstandungen', icon: '$vuetify.icons.textBox', route: '/retaxen', disabled: !this.$store.state.module.retaxRead || !this.hasCurrentLE },
                    { title: 'Neuer Einspruch', icon: '$vuetify.icons.faq', route: '/einspruchadd', disabled: !this.$store.state.module.retaxWrite || !this.hasCurrentLE },
                    { title: 'Einspruchsliste', icon: '$vuetify.icons.listStatus', route: '/einspruchliste', disabled: !this.$store.state.module.retaxRead || !this.hasCurrentLE },
                    '',
                    { title: 'Zuzahlungsprüfung', icon: '$vuetify.icons.accountSearch', route: '/zuzahlung', disabled: !this.$store.state.module.zuzahlungRead  },
                    { title: 'Zuz.-Rückforderung', icon: 'mdi-arrow-u-left-bottom', route: '/zzrefund', disabled: !this.$store.state.module.zzRueckforderungRead || !this.hasCurrentLE },
                    '',
                    { title: 'Einstellungen', icon: '$vuetify.icons.cog', route: '/settings', disabled: false },
                ]

                if (this.$store.state.currentUser.canSwitchIK && this.$store.state.currentUser.supportUser) {
                    items.push(
                        { title: 'Support', icon: 'mdi-face-agent', route: '/usersupport', disabled: false },
                    )
                }
                return items;
            },
            currentLE() {
                return this.$store.state.currentUser?.leistungserbringer;
            },

            hasCurrentLE() {
                return this.$store.state.currentUser.leistungserbringer ? true : false;
            },

            isUserLoggedIn() {
                return this.$store.state.currentUser
                    //&& this.$store.state.currentUser.leistungserbringer
                    && !this.sessionExpired;
            },

            timeToLogoutText() {
                if (!this.timeToLogout)
                    return null;

                return String(this.timeToLogout.hours()).padStart(2, '0') !== '00' ? String(this.timeToLogout.hours()).padStart(2, '0') + ':' +
                    String(this.timeToLogout.minutes()).padStart(2, '0') + ':' +
                    String(this.timeToLogout.seconds()).padStart(2, '0') :
                    String(this.timeToLogout.minutes()).padStart(2, '0') + ':' +
                    String(this.timeToLogout.seconds()).padStart(2, '0');
            },

            isIE() {
                // Detect Internet Explorer 6-11
                return ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true));
            },

            minimizeNavigationDrawerForBreakpoint: {
                get() {
                    return this.$vuetify.breakpoint.md && this.minimizeNavigationDrawer;
                },
                set(value) {
                    this.minimizeNavigationDrawer = value;
                }
            },

            isDevelopment() {
                return process.env.NODE_ENV === 'development';
            },

            supportUser() {
                return this.$store.state?.currentUser?.supportUser
            }
            
        },

        watch: {
            currentLE(newValue) {
                //console.log('this.$store.state.module.retaxRead', this.$store.state.module.retaxRead);
                if (newValue && this.$store.state.module.retaxRead) { 
                    this.execSync(this.updateBMonate);
                }

                if (newValue && this.$store.state.currentUser.canSwitchIK && this.$store.state.currentUser.supportUser && !this.navItemList.find((e) => e.title === 'Support')) {
                    this.navItemList.push('',
                        { title: 'Support', icon: 'mdi-face-agent', route: '/usersupport', disabled: false },
                    )
                }
            },
           
        },

        mounted() {

            //console.log('this.$store.state.module', this.$store.state.module);
            this.$nextTick(() => {
                this.resizeObserver = new ResizeObserver(this.onResize);
                this.resizeObserver.observe(this.$refs.footer.$el);
                this.resizeObserver.observe(this.$refs.header.$el);

                const main = document.getElementsByTagName('main')[0];
                this.resizeObserver.observe(main);

                let spBookMe = document.createElement('script')
                spBookMe.setAttribute('src', '//widget.simplybook.it/v2/widget/widget.js')
                document.head.appendChild(spBookMe);

                this.getAnrede();
            });

            if (this.$store.state.currentUser) {
                // token erneuern und timer zurücksetzen, bei Fehler ausloggen
                this.execSync(this.resetLogoutTimer);
                this.execSync(this.refreshStoreData);

                //Module aufrufen
                this.execSync(this.getModule);
                
                //Buchungsmonate aufrufen
               this.updateBMonate();
                
                
                
            }
        },

        beforeDestroy() {
            this.resizeObserver.unobserve(this.$refs.header.$el);
            this.resizeObserver.unobserve(this.$refs.footer.$el);

            const main = document.getElementsByTagName('main')[0];
            this.resizeObserver.unobserve(main);
        },

        methods: {

            execSync(promise) {
                /* Bestimmte promises aus hooks oder eventhandlern müssen
                 * synchron ausgeführt werden, damit z.B. Definitionen
                 * vor dem rendern verfügbar sind.
                 */
                (async () => { await promise(); })();
            },

            async refreshStoreData() {

                if (!this.$store.state.currentUser)
                    return;

                await this.updateDefCache();
                if (this.$store.state.module.retaxRead)
                    await this.updateBMonate();
            },

            async updateBMonate() {
                try {
                    let le = this.$store.state.currentUser.leistungserbringer;
                    let response = await this.$http.get('retax/monate', {
                        params: { Leistungserbringer: le }
                    });

                    if (response.status === 200 || response.status === 204) {
                        this.$store.commit('updateBMonate', response.data);
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },

            startLogoutTimer() {

                // evtl. existierenden Timer stoppen
                this.stopLogoutTimer();

                this.intervalId = workerTimers.setInterval(() => {
                    if (this.timeToLogout > 0) {
                        this.timeToLogout = this.$moment.duration(this.timeToLogout.asSeconds() - 1, 'seconds');
                    }
                    else {
                        // session expired
                        this.sessionExpired = true;
                        this.stopLogoutTimer();
                        this.logout();
                    }
                }, 1000);
            },

            stopLogoutTimer() {
                if (this.intervalId) {
                    //console.log('logouttimer Stop: ', String(this.timeToLogout.hours()).padStart(2, '0') + ':' +String(this.timeToLogout.minutes()).padStart(2, '0') + ':' +
                    //    String(this.timeToLogout.seconds()).padStart(2, '0'));
                    workerTimers.clearInterval(this.intervalId);
                    this.intervalId = null;
                }
            },

            async resetLogoutTimer() {
                try {
                    this.showWaitDialog = true;
                    let response = null;
                    console.log('this.$store.state.currentUser.canSwitchIK: ', this.$store.state.currentUser.canSwitchIK);
                    console.log('this.$store.state.currentUser.supportUser: ', this.$store.state.currentUser.supportUser);
                    console.log('this.sessionExpired: ', this.sessionExpired);
                    console.log('this.timeToLogout: ', this.timeToLogout);
                    if (this.$store.state.currentUser.canSwitchIK && this.$store.state.currentUser.supportUser ) {
                        //logout time für EBT Team
                        if (this.timeToLogout == null ) { 

                            //response =
                            await this.$http.post('auth/refresh-token-ebt').then((response) => {

                                console.log('resetLogoutTimer(refresh-token-ebt): response', response);
                                if (response.status === 200 && response.data) {
                                    console.log('resetLogoutTimer(refresh-token-ebt: innen): response', response);
                                    let currentUser = this.$store.state.currentUser;
                                    currentUser.jwtToken = response.data.jwtToken;
                                    this.$store.commit('updateCurrentUser', currentUser);
                                    this.timeToLogout = this.$moment.duration(9, 'hours'); // 9 Stunden
                                }

                            }).catch(error => {
                                if (error.response) {
                                    //console.log(error.response.data.message); 
                                    this.loginError = error.response.data.message;

                                    // refreshtoken could be invalid
                                   this.logout();
                                }
                            });

                            
                        }

                    }
                    else { 

                    // use refreshToken cookie to get new JWT token
                    // cookie is added automatically to the request
                        //response =
                        await this.$http.post('auth/refresh-token'
                        ).then((response) => {
                           
                                console.log('resetLogoutTimer(refresh-token): response', response);
                                if (response.status === 200 && response.data) {
                                    console.log('resetLogoutTimer(refresh-token: innen): response', response);
                                    let currentUser = this.$store.state.currentUser;
                                    console.log('resetLogoutTimer(refresh-token: nach let currentUser): currentUser', currentUser);
                                    currentUser.jwtToken = response.data.jwtToken;
                                    console.log('resetLogoutTimer(refresh-token: nach currentUser.jwtToken): currentUser.jwtToken', currentUser.jwtToken);
                                    this.$store.commit('updateCurrentUser', currentUser);
                                } this.timeToLogout = this.$moment.duration(30, 'minutes'); // 30 Minuten
                            
                        }).catch(error => {
                            if (error.response) {
                                //console.log(error.response.data.message); 
                                this.loginError = error.response.data.message;

                                // refreshtoken could be invalid
                               this.logout();
                            }
                        });
                                
                       
                    }
 
                    this.sessionExpired = false;
                    this.startLogoutTimer();
                }
                catch (error) {
                    if (error.response)
                        this.loginError = error.response.data.message;

                    // refreshtoken could be invalid
                    await this.logout();
                }
                finally {
                    this.showWaitDialog = false;
                }
            },

            async deactivateLogoutTimer() {
                try {
                    if (!this.isDevelopment)
                        return;

                    this.showWaitDialog = true;

                    // use refreshToken cookie to get new JWT token
                    // cookie is added automatically to the request
                    let response = await this.$http.post('auth/refresh-token-max');
                    if (response.status === 200 && response.data) {
                        let currentUser = this.$store.state.currentUser;
                        currentUser.jwtToken = response.data.jwtToken;
                        this.$store.commit('updateCurrentUser', currentUser);
                    }

                    this.stopLogoutTimer();
                }
                catch (error) {
                    if (error.response)
                        this.loginError = error.response.data.message;

                    // refreshtoken could be invalid
                    await this.logout();
                }
                finally {
                    this.showWaitDialog = false;
                }
            },

            async logout() {
                try {
                    if (this.$store.state.currentUser) {
                        await this.$http.post('/auth/logout');
                    }
                }
                finally {
                    this.stopLogoutTimer();
                    this.$nextTick(() => {
                        this.$store.commit('updateCurrentUser', null);

                        this.$store.commit('updateModule', {
                            retaxRead: false,
                            retaxWrite: false,
                            zuzahlungRead: false,
                            zzRueckforderungRead: false,
                            zzRueckforderungWrite: false
                        });

                        this.timeToLogout = null;
                    });

                    if (this.$route.path !== '/login')
                        this.$router.push('/login').catch(() => { });

                    const element = document.querySelector(".simplybook-widget-button");
                    if (element) element.remove();
                }
            },

            async updateDefCache() {
                try {
                    let response = await this.$http.get('def');
                    if (response.status === 200) {
                        var result = JSON.parse(response.data);
                        result.forEach(i => {
                            // Properties begannen bisher immer lowercase, deshalb hier auch zur Kompatibilität.
                            let key = i.Key[0].toLowerCase() + i.Key.substring(1);
                            this.$store.commit('updateDefCache', { field: key, value: i.Value });
                        });
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },

            toggleNavigationDrawer() {

                // xs + sm: drawer als overlay aufklappen
                this.showNavigationDrawer = true;

                // md: minimiert auf- und zuklappen
                this.minimizeNavigationDrawer = !this.minimizeNavigationDrawer;
            },

            onResize() {

                // Automatische Layoutberechnung in vuetify 2 ist verbuggt (bei footer mit dynamischer Höhe).
                // Daher muss hier mit einem ResizeObserver gearbeitet werden.

                let headerHeight = this.$refs.header.$el.offsetHeight + 'px';
                let footerHeight = this.$refs.footer.$el.offsetHeight + 'px';
                let nav = document.getElementsByTagName('nav')[0];
                let main = document.getElementsByTagName('main')[0];

                if (nav) {
                    nav.style.maxHeight = 'calc(100% - (' + headerHeight + ' + ' + footerHeight + '))';
                    //console.log('nav.style.maxHeight auf ' + nav.style.maxHeight + ' gesetzt.');
                }

                main.style.paddingBottom = footerHeight;
                //console.log('main.style.paddingBottom auf ' + main.style.paddingBottom + ' gesetzt.');
            },

            convertDatum(datum) {
                //Zeit aus Datum entfernen
                let dt = datum;
                if (datum) {
                    dt = new Date(datum);
                }
                else {
                    dt = new Date();
                }
                dt.setHours(0, 0, 0, 0);
                return Date.parse(dt);
            },

            findUserRight(i) {
                //Benutzerrecht finden
                console.log('findUserRight(i) this.$store.state.currentUser: ', this.$store.state.currentUser);
                let ix = this.$store.state.currentUser.rechte.findIndex(r => r.recht === i); 
                return ix !== -1 
            },

            async getModule() {
                try {

                    let response = await this.$http.get('module');
                    if (response.status === 200) {
                        var result = response.data;
                        let module = {
                            retaxRead: false,
                            retaxWrite: false,
                            zuzahlungRead: false,
                            zzRueckforderungRead: false,
                            zzRueckforderungWrite: false
                        };

                        let dateNow = this.convertDatum("");
                        let dateOpen = Date.parse("12/31/9999");

                        result.forEach(r => {
                            //console.log('r.modul: ', r.modul, 'r.gbDatum', r.gbDatum, 'dateNow: ', dateNow, 'dateOpen: ', dateOpen);

                            let gbDate = this.convertDatum(r.gbDatum);

                            switch (r.modul) {
                                case 1:
                                    //let modulrights = this.$store.state.currentUser.rechte.findIndex(r => r.recht === 1 || 2 )
                                    if (gbDate === dateOpen) {
                                        module.zuzahlungRead = true && this.findUserRight(3);
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                        module.zzRueckforderungWrite = true && this.findUserRight(8);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.zzRueckforderungRead = true && this.findUserRight(7);
                                    break;
                                case 2:
                                    if (gbDate === dateOpen) {
                                        module.retaxRead = true && this.findUserRight(1);
                                        module.retaxWrite = true && this.findUserRight(2);
                                    }
                                    else if (gbDate >= dateNow && gbDate < dateOpen)
                                        module.retaxRead = true && this.findUserRight(1);
                                    break;
                            }
                        });

                        this.$store.commit('updateModule', {
                            retaxRead: module.retaxRead,
                            retaxWrite: module.retaxWrite,
                            zuzahlungRead: module.zuzahlungRead,
                            zzRueckforderungRead: module.zzRueckforderungRead,
                            zzRueckforderungWrite: module.zzRueckforderungWrite
                        });

                        this.startWidget();
                    }
                }
                catch (error) {
                    if (error.response) {
                        this.$store.commit('updateCurrentError', error.response.data);
                    }
                }
            },

            async getAnrede() {

                await this.$http.get('def/anrede').then((response) => {
                    if (response.status === 200) {
                        this.$store.commit('updateAnrede', response.data);
                    }
                });

            },

            startWidget() {
                var widget = new SimplybookWidget({
                    "widget_type": "button",
                    "url": "https:\/\/rueckruf-service.gfs-web.de",
                    "theme": "blur",
                    "theme_settings": {
                        "timeline_hide_unavailable": "0",
                        "hide_past_days": "1",
                        "timeline_modern_display": "as_table",
                        "sb_base_color": "#c2c2c2",
                        "display_item_mode": "block",
                        "body_bg_color": "#ffffff",
                        "dark_font_color": "#000000",
                        "light_font_color": "#ffffff",
                        "btn_color_1": "#e6e01d",
                        "sb_company_label_color": "#000000",
                        "hide_img_mode": "0",
                        "sb_busy": "#c2c2c2",
                        "sb_available": "#e6e01d"
                    }, "timeline": "modern_week",
                    "datepicker": "inline_datepicker",
                    "is_rtl": false,
                    "app_config": {
                        "clear_session": 1,
                        "allow_switch_to_ada": 0,
                        "predefined": []
                    },
                    "button_title": "R\u00fcckruf-Service Team Widerspruch",
                    "button_background_color": "#e6e01d",
                    "button_text_color": "#ffffff",
                    "button_position": "right",
                    "button_position_offset": "85%"
                });
            
            }
        }
    }
</script>

<style scoped>

    /* lokale styles */

    .kassenlogo {
        border: 2px solid lightgrey;
    }

    .row.extra-dense {
        margin: -2px;
    }

    .row + .row.extra-dense {
        margin-top: 2px;
    }

    .row.extra-dense > .col, .row.extra-dense > [class*=col-] {
        padding: 2px;
    }

    /*#region Header*/
    #appBarNavIcon::before {
        background-color: transparent !important;
    }

    >>> .v-toolbar__content {
        min-height: 56px !important;
        padding: 0px !important;
    }
    /*#endregion*/

    /*#region Recaptcha badge*/
    #recaptcha-container {
        position: relative;
    }

    >>> .grecaptcha-badge {
        position: absolute !important;
        width: 70px !important;
        overflow: hidden !important;
        transition: all 0.3s ease !important;
        right: 0px !important;
    }
      
    >>> .grecaptcha-badge:hover, >>> .grecaptcha-badge:focus, >>> .grecaptcha-badge:active {
            width: 256px !important;
    }
    /*#endregion*/

</style>

<style>
    /* globale styles */

    /*#region v-card: Texte sollten nicht an jeder Stelle umgebrochen werden */
    #serviceportal .v-card__title {
        word-break: normal;
    }
    /*#endregion*/

    /*#region v-card: Konsistentes Padding - egal, ob inner- oder außerhalb von Dialogen. */
    #serviceportal .v-card__actions {
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 16px;
    }

    #serviceportal .v-dialog > .v-card > .v-card__title,
    #serviceportal .v-dialog > .v-card > .v-card__actions {
        padding-bottom: 16px;
    }
    /*#endregion*/

    /*#region v-card: Rahmen und Schatten in gelb */
    #serviceportal .v-card:not([borderless]) {
        border: solid 2px rgb(207 197 3) !important;
        box-shadow: 0 3px 1px -2px rgb(207 197 3 / 20%), 0 2px 2px 0 rgb(207 197 3 / 14%), 0 1px 5px 0 rgb(207 197 3 / 12%) !important;
    }

    #serviceportal .v-card[borderless] {
        border: none !important;
        box-shadow: none !important;
    }
    /*#endregion*/

    /*#region v-dialog: Breakpoints für Dialoge (außer fullscreen)*/
    /*
        Achtung:
        attach-Property von v-dialog (vuetify) meiden, da dies durch automatisch berechnete z-index zu Problemen führte.
        Dialoge werden nun wieder an der von vuetify bestimmten Stelle im DOM angefügt und die Maximalbreite direkt
        mit Mediaqueries limitiert, statt mit dem vuetify Gridsystem (v-row / v-col).
    */
    .v-dialog__content {
        padding: 24px;
    }

    /* xs */
    .v-dialog:not(.v-dialog--fullscreen) {
        max-width: 100%;
        flex: 0 0 100%;
    }

    /* sm */
    @media (min-width: 600px) {
        .v-dialog:not(.v-dialog--fullscreen) {
            max-width: 66.67%;
            flex: 0 0 66.67%;
        }
    }

    /* md */
    @media (min-width: 960px) {
        .v-dialog:not(.v-dialog--fullscreen) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }

    /* lg + xl */
    @media (min-width: 1264px) {
        .v-dialog:not(.v-dialog--fullscreen) {
            max-width: 33.32%;
            flex: 0 0 33.32%;
        }
    }
    /*#endregion*/

    /*#region v-dialog: Inaktive Dialoge dürfen keinen Platz im Layout reservieren */
    #serviceportal .v-dialog__content:not(.v-dialog__content--active) {
        display: none;
    }
    /*#endregion*/

    /*#region v-navigation-drawer: Bugfix für Mobilgeräte, wenn 100vh nicht konstant ist */
    /* siehe https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser */
    #serviceportal .v-navigation-drawer {
        height: 100% !important;
    }
    /*#endregion*/

    /*#region table: Schriftgröße in Tabellen erben */
    #serviceportal td, #serviceportal th {
        font-size: inherit;
    }
    /*#endregion*/

    /*#region v-data-table: Padding reduzieren */
    #serviceportal .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
    #serviceportal .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    #serviceportal .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
    #serviceportal .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
    #serviceportal .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
    #serviceportal .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0px 8px;
    }
    /*#endregion*/

    /*#region v-data-table: mobile-Tabellenzeilen etwas komprimieren*/
    #serviceportal .v-data-table--dense > .v-data-table__wrapper .v-data-table__mobile-row {
        min-height: 32px;
    }

    #serviceportal .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:first-child {
        padding-top: 8px;
    }

    #serviceportal .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row:last-child {
        padding-bottom: 8px;
    }
    /*#endregion*/

    /*#region v-data-table: margin von select-checkboxes in v-data-table (normale Ansicht) entfernt */
    #serviceportal .v-data-table tr .v-input--selection-controls__input {
        margin-right: 0px;
    }
    /*#endregion*/

    /*#region v-data-table: select-all-checkbox in mobile-Ansicht linksbündig */
    #serviceportal .v-data-table-header-mobile__select {
        justify-content: flex-start;
    }
    /*#endregion*/

    /*#region Worttrennung wo sinnvoll */
    #serviceportal .text-hyphenate {
        hyphens: auto;
    }
    /*#endregion*/

    /*#region Langen Text in Buttons wrappen */
    /*siehe auch https://github.com/vuetifyjs/vuetify/issues/11357 */
    #serviceportal .v-btn-multiline {
        height: auto !important;
    }

        #serviceportal .v-btn-multiline.v-size--default {
            min-height: 36px !important;
        }

        #serviceportal .v-btn-multiline.v-size--large {
            min-height: 44px !important;
        }

        #serviceportal .v-btn-multiline.v-size--x-large {
            min-height: 52px !important;
        }

        #serviceportal .v-btn-multiline .v-btn__content {
            width: 100% !important;
            white-space: normal;
        }
    /*#endregion*/

    /*#region Imitiert dense prop auf v-select, aber ohne die font-size des dropdown zu verändern */
    .v-select.custom-dense {
        padding-top: 0px;
    }

        .v-select.custom-dense > .v-input__control > .v-input__slot {
            margin-bottom: 4px;
        }

        .v-select.custom-dense .v-label {
            top: 4px;
        }

        .v-select.custom-dense input {
            padding: 4px 0px 2px;
        }

        .v-select.custom-dense .v-input__append-inner {
            margin-top: 0px;
        }

        .v-select.custom-dense .v-select__selection--comma {
            margin: 5px 4px 3px 0;
        }
    /*#endregion*/

    /*#region Angedeutete Scrollfunktion*/
    .scroll-gradient .v-data-table__wrapper {
        background-image: linear-gradient(to right, white 20px, transparent), linear-gradient(to left, white 20px, transparent), linear-gradient(to right, rgb(0 0 0 / 20%), transparent), linear-gradient(to left, rgb(0 0 0 / 20%), transparent);
        background-position: left center, right center, left center, right center;
        background-repeat: no-repeat;
        background-size: 40px 100%, 40px 100%, 20px 100%, 20px 100%;
        background-attachment: local, local, scroll, scroll;
    }
    /*#endregion*/

    /*#region Transparenz zurücksetzen (z.B. bei Tooltips)*/
    .opacity-1 {
        opacity: 1 !important;
    }
    /*#endregion*/

    /*#region Hintergrundfarben für Tabellenzeilen*/
    .alternating-row-colors > div > table > tbody > tr:nth-of-type(even) {
        background-color: white;
    }

    .alternating-row-colors > div > table > tbody > tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .04);
    }

    .alternating-row-colors > div > table > tbody > tr:nth-of-type(even).v-data-table__selected {
        background-color: rgba(207, 197, 3, .06);
    }

    .alternating-row-colors > div > table > tbody > tr:nth-of-type(odd).v-data-table__selected {
        background-color: rgba(207, 197, 3, .12);
    }

    .alternating-row-colors > div > table > tbody > tr:not(.v-data-table__expanded__content):hover,
    .alternating-row-colors > div > table > tbody > tr.highlighted {
        background-color: rgba(207, 197, 3, .20) !important;
    }
    /*#endregion*/

    /*#region Ergänzung Schriftgrad 600 (semibold) */
    #serviceportal .font-weight-semibold {
        font-weight: 600;
    }
    /*#endregion*/

    /*#region Anpassung der Divider Stärke bzw. Dicke */
    .dividerClass {
        border-width: 2px !important;
        border-color: #cfc503 !important;
        height: 100%;
    }
</style>

