import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import moment from 'moment'
import VueMoment from 'vue-moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './styles/portal.scss'
import { VueMaskDirective } from 'v-mask'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

var eventBus = new Vue();
export default eventBus;

// Axios for HTTP requests
axios.defaults.baseURL = '/api/v1/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    if (user && user.jwtToken)
        config.headers.Authorization = 'Bearer ' + user.jwtToken;
    return config;
});
Vue.prototype.$http = axios;

// Vee-Validate
/* RL@26.07.2023: 
 * Wird aktuell nicht genutzt. Ziemlich komplexes setup.
 * Sollte dies tats�chlich mal genutzt werden, dann m�ssen die gew�nschten rules
 * und lokalierten messages erst noch importiert werden: 
 * https://vee-validate.logaretm.com/v3/guide/rules.html#importing-the-rules
 */ 
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Moment.js
Vue.prototype.moment = moment;
Vue.use(VueMoment, { moment });

// VueReCaptcha (GFS Sitekey)     
Vue.use(VueReCaptcha, {
    siteKey: '6LcBJ8caAAAAAASwhKwP1GqcVriEl0VqYzY-tFz2',
    loaderOptions: {
        explicitRenderParameters: {
            container: 'recaptcha-container',
            badge: 'bottomright',
            size: 'invisible'
        }
    }
});

// Vorformatierung im Eingabefeld 
Vue.directive('mask', VueMaskDirective);

// VCurrencyField
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
    locale: 'de',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null,
    defaultValue: null,
    valueAsInteger: false,
    allowNegative: false
});

// Production setting
Vue.config.productionTip = false;

// Key Code Aliases
Vue.config.keyCodes.F1 = 112;
Vue.config.keyCodes.F5 = 116;
Vue.config.keyCodes.F9 = 120;


new Vue({
    vuetify,
    store,
    router,
    beforeCreate() { this.$store.commit('initializeStore'); },
    render: h => h(App)
}).$mount('#app')
