import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
		values: {
			pdf: 'mdi-file-pdf-box',
			info: 'mdi-information-outline',
			accountSearch: 'mdi-account-search-outline',
			formatList: 'mdi-format-list-bulleted',
			calendar: 'mdi-calendar',
			chevronLeft: 'mdi-chevron-left',
			check: 'mdi-check-circle',
			close: 'mdi-close-circle',
			clipboard: 'mdi-clipboard-check',
			cog: 'mdi-cog-outline',
			face: 'mdi-face',
			faceWoman: 'mdi-face-woman',
			faq: 'mdi-frequently-asked-questions',
			file: 'mdi-file',
			fileAdd: 'mdi-file-plus-outline',
			fileDownload: 'mdi-download',
			fileFind: 'mdi-file-find',
			home: 'mdi-home-outline',
			paperclip: 'mdi-paperclip',
			comment: 'mdi-comment-quote-outline',
			textBox: 'mdi-text-box-outline',
			listStatus: 'mdi-list-status',
			playArrow: 'mdi-play',
			keyChange: 'mdi-key-change',
			logout: 'mdi-power',
			accountPlus: 'mdi-account-plus',
			database: 'mdi-database',
			pharmacy: 'mdi-pharmacy',
			arrowLeft: 'mdi-arrow-left',
			contentSave: 'mdi-content-save',
			accountSwitch: 'mdi-account-switch',
			removeAccount: 'mdi-account-remove',
			accountKey: 'mdi-account-key',
			accountOk: 'mdi-account-check',
			email:'mdi-email',
			image: 'mdi-image-area',
			error: 'mdi-alert-circle',
			clockAlert: 'mdi-clock-alert',
			checkEmail: 'mdi-email-check',
			delete: 'mdi-delete-forever-outline',
			fileShow: 'mdi-file-eye-outline',
			eyeOff: 'mdi-eye-off-outline',
			eyeOn: 'mdi-eye-outline',
			renew: 'mdi-autorenew',
			arrowRight: 'mdi-arrow-right',
			lock: 'mdi-lock-outline',
			ok: 'mdi-check',
			new: 'mdi-alert-circle-outline',
			featureSearch: 'mdi-feature-search',
			textSearch: 'mdi-text-search',
			undo: 'mdi-undo',
			twoFactorAuthentication: 'mdi-two-factor-authentication',
			stop: 'mdi-stop',
			currencyEuro: 'mdi-currency-eur'
		}
	},

	theme: {
		themes: {
			light: {
				primary: '#cfc503',
				secondary: '#e7e01e',
				info: '#ebf00f',
				accent: '#689F38'
			},
			dark: {
				primary: '#cfc503',
				secondary: '#e7e01e',
				info: '#ebf00f',
				accent: '#689F38'
			}
		}
	},

	lang: {
		locales: { de },
		current: 'de'
	}
});
