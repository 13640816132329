import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

Vue.use(Router)

//const Role = {
//	Admin: 'Admin',
//	User: 'User'
//}

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Home',
			meta: { authorize: [] }, 
			component: Home
		},
		{
			path: '/login',
			name: 'login',
			component: () => import(/* webpackChunkName: "login" */ '../components/Login.vue'),
			props: route => ({
				oemtoken: route.query.oemtoken
			})
		},
		{
			path: '/zuzahlung',
			name: 'Zuzahlung',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "zuzahlung" */ '../views/Zuzahlung.vue')
		},
		{
			path: '/zzrefund',
			name: 'Zuzahlungsrückforderung',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "zzrefund" */ '../views/ZzRefund.vue')
		},
		{
			path: '/retaxen',
			name: 'Retaxen',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "retaxen" */ '../views/Retaxen.vue')
		},
		{
			path: '/kontakt',
			name: 'Kontakt',
			component: () => import(/* webpackChunkName: "kontakt" */ '../components/Kontakt.vue')
		},
		{
			path: '/datenschutz',
			name: 'Datenschutz',
			component: () => import(/* webpackChunkName: "datenschutz" */ '../components/Datenschutz.vue')
		},
		{
			path: '/faq',
			name: 'FAQ',
			component: () => import(/* webpackChunkName: "faq" */ '../components/FAQ.vue')
		},
		{
			path: '/terms',
			name: 'Terms',
			component: () => import(/* webpackChunkName: "terms" */ '../components/Terms.vue')
		},
		{
			path: '/termsallgemein',
			name: 'termsallgemein',
			component: () => import(/* webpackChunkName: "termsallgemein" */ '../components/TermsAllgemein.vue')
		},
		{
			path: '/termsbeanstandungen',
			name: 'termsbeanstandungen',
			component: () => import(/* webpackChunkName: "termsbeanstandungen" */ '../components/TermsBeanstandungen.vue')
		},
		{
			path: '/termszuzahlung',
			name: 'termszuzahlung',
			component: () => import(/* webpackChunkName: "termszuzahlung" */ '../components/TermsZuzahlung.vue')
		},
		{
			path: '/settings',
			name: 'Einstellungen',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
		},
		{
			path: '/einspruchadd',
			name: 'Einspruchsassistent',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "einspruch" */ '../views/EinspruchAdd.vue')
		},
		{
			path: '/einspruchliste',
			name: 'Einspruchsliste',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "einspruch" */ '../views/EinspruchListe.vue')
		},
		{
			path: '/registrierung',
			name: 'registrierung',
			component: () => import(/* webpackChunkName: "registrierung" */ '../components/Registrierung.vue'),
			props: route => ({
				code: route.query.regcode,
				verify: route.query.vercode
			})
		},
		{
			path: '/persoenlichedaten',
			name: 'persoenlichedaten',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "persoenlichedaten" */ '../components/PersoenlicheDaten.vue')
		},
		{
			path: '/modulauswahl',
			name: 'modulauswahl',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "modulauswahl" */ '../components/Modulauswahl.vue')
		},
		{
			path: '/kontoloeschen',
			name: 'kontoloeschen',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "kontoloeschen" */ '../components/KontoLoeschen.vue')
		},
		{
			path: '/stammdaten',
			name: 'stammdaten',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "stammdaten" */ '../components/Stammdaten.vue')
		},
		{
			path: '/passwortaenderung',
			name: 'passwortaenderung',
			meta: { authorize: [] },
			component: () => import(/* webpackChunkName: "passwortaenderung" */ '../components/PasswortAenderung.vue')
		},
		{
			path: '/passwortzuruecksetzen',
			name: 'passwortzuruecksetzen',
			component: () => import(/* webpackChunkName: "passwortzuruecksetzen" */ '../components/PasswortZuruecksetzen.vue')
		},
		{
			path: '/authenticatorreset',
			name: 'authenticatorreset',
			component: () => import(/* webpackChunkName: "passwortzuruecksetzen" */ '../components/AuthenticatorReset.vue')
		},
		{
			path: '/benutzeranmeldung',
			name: 'benutzeranmeldung',
			component: () => import(/* webpackChunkName: "benutzeranmeldung" */ '../components/BenutzerAnmeldung.vue')
		},

		{
			path: '/usersupport',
			name: 'usersupport',
			component: () => import(/* webpackChunkName: "usersupport" */ '../components/UserSupport.vue')
		},
		// otherwise redirect to home
		{ path: '*', redirect: '/' }
	]

});

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	const { authorize } = to.meta;
	const currentUser = JSON.parse(sessionStorage.getItem('currentUser')); //authenticationService.currentUserValue;

	// Route needs authorization?
	if (authorize) {
		// currentUser needs to be in sessionStorage and roles need to match (if there are any roles on the route)
		if (!currentUser || (authorize.length && !authorize.includes(currentUser.role))) {
			// not logged in so redirect to login page
			return next({ path: '/login' });
		}
	}

	// FIXME: Nur temporär wg. DEV-1709, danach zurückbauen!
	if (store.state.serviceMessage) {
		if (['Zuzahlung', 'Retaxen', 'Einspruchsassistent', 'Einspruchsliste'].includes(to.name)) {
			// go home
			return next({ path: '/' });
        }
    }

	return next();
});

export default router;
