<template>
    <v-container fluid class="fill-height pa-0">
        <v-row class="justify-center">
            <v-col cols="12" sm="9" md="6" lg="7" xl="5">

                <v-card v-if="showSwitchWindow">
                    <v-card-title class="primary--text text-h5 text-md-h4 flex-nowrap align-start">
                        Anmeldung
                    </v-card-title>
                    <v-card-text class="text-body-1 text--primary">
                        <p>
                            Bitte geben Sie den gewünschten Leistungserbringer an.<br />
                            Sie haben später zusätzlich die Möglichkeit, diesen über das Menü "Einstellungen" zu wechseln.
                        </p>
                        <switch-ik buttonLabel="Anmelden" @switchDone="loginDone" />
                    </v-card-text>
                </v-card>
                <v-card v-else-if="$store.state.currentUser">
                    <v-card-text class="text-body-1 text--primary">
                        <v-row>
                            <v-col>
                                <p class="text-h6 text--secondary mb-0 mt-4">
                                    {{ $store.state.currentUser.leistungserbringer }}
                                </p>
                            </v-col>
                            <v-col cols="6" class="mr-n16">
                                <p class="text-h6 text--secondary mb-0 mt-4" v-if="$store.state.currentUser.leistungserbringer != $store.state.currentUser.loginName">
                                    Benutzername: {{ $store.state.currentUser.loginName }}
                                </p>
                            </v-col>
                        </v-row>
                        <p class="text-h4">
                            {{ $store.state.currentUser.apoName }}
                        </p>
                        <p class="text-uppercase text--secondary">
                            {{ $store.state.currentUser.email }}
                        </p>
                        <p class="text-h6 text--secondary">
                            Herzlich Willkommen im Service-Portal der GFS. <br />
                            Sie sind nun angemeldet.
                        </p>
                        <v-alert border="left"
                                 colored-border
                                 type="info"
                                 mb-0>
                            Aktuell stehen Ihnen die Beanstandungen der teilnehmenden Krankenkassen BARMER, DAK Gesundheit, HEK, Mobil Krankenkasse und KKH Kaufmännische Krankenkasse zur Bearbeitung zur Verfügung.
                            Ein Einspruch kann somit nur für diese Krankenkassen im Service-Portal angelegt werden. Sobald weitere Krankenkassen teilnehmen,
                            erhalten Sie eine Benachrichtigung an die bei der Registrierung hinterlegten E-Mail-Adresse.
                        </v-alert>

                        <v-alert v-if="showLeseBestaetigungWarnung"
                                 dense outlined
                                 type="warning" class="mt-4 mb-0">
                            {{leseBestaetigungWarnung}}
                        </v-alert>

                        <p v-show="$store.state.currentUser.gbDatum" class="red--text mb-0 mt-4">
                            Gemäß Ihrer Kündigung wird Ihr Konto zum {{ moment($store.state.currentUser.gbDatum).format('DD.MM.YYYY') }} gelöscht.
                        </p>
                        <!--<br />Sie können diese in den <router-link to="/settings">Einstellungen</router-link> stornieren.-->
                        <!--<p v-show="showUndoUnregisterSuccess" class="green--text mb-0 mt-4">
                Ihre Kündigung wurde storniert.
            </p>-->
                        <p v-show="$store.state.serviceMessage" class="text-h6 red--text mt-4">
                            <span v-html="$store.state.serviceMessage" />
                        </p>

                    </v-card-text>
                    <v-card-actions v-if="!$store.state.serviceMessage" class="justify-center">
                        <v-btn color="primary" :disabled="!this.$store.state.module.retaxRead" type="button" to="/retaxen">
                            Beanstandungsverfahren
                        </v-btn>

                        <v-btn color="primary" :disabled="!this.$store.state.module.zuzahlungRead" type="button" to="/zuzahlung">
                            Zuzahlungsprüfung
                        </v-btn>
                        <v-btn color="primary" :disabled="!this.$store.state.module.zzRueckforderungRead" type="button" to="/zzrefund">
                            Zuzahlungsrückforderung
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
        </v-row>

        <!-- Info-Fenster zeigen -->
        <dialog-mitteilungen :showDialog.sync="showInfoFenster"  :infoData="infoDaten" :agbDaten="agbDaten" @ShowInfoDialog="OnShowInfoFenster"/>
    </v-container>
</template>

<script>
    import DialogMitteilungen from '@/components/DialogMitteilungen.vue'
    import SwitchIk from '@/components/SwitchIK.vue';
    import eventBus from '@/main';

    export default {

        name: 'Home',

        components: {
            DialogMitteilungen,
            SwitchIk,
        },

        data() {
            return {
                showUndoUnregisterSuccess: false,
                showInfoFenster: false,
                infoDaten: [{}],
                agbDaten: [{}],
                showLeseBestaetigungWarnung: false,
                showSwitchWindow: false
             
            }
        },

        watch: {
            '$store.state.leseBestaetigungWarnung'() {
                this.showLeseBestaetigungWarnung = this.$store.state.leseBestaetigungWarnung.length > 1 ? true : false;
            },

            //showSwitchWindow() { 
            //    this.showSwitchWindow = this.showSwitchWindow;
            //}
            '$store.state.currentUser.leistungserbringer'() { 
               this.showSwitchWindow = typeof (this.$store.state.currentUser?.canSwitchIK) !== "undefined" && typeof this.$store.state.currentUser.leistungserbringer === "undefined" ? this.$store.state.currentUser?.canSwitchIK : false;

            }
        },

        activated() {
            this.initData();
            this.$http.get('mitteilung')
                .then(rsp => {
                    if (rsp.status === 200 && rsp.data) {
                        this.infoDaten = rsp.data;
                        this.showInfoFenster = true;
                    }
                        
                })
                .catch(error => {
                    console.log(error.toJSON());
                });

             this.$http.get('mitteilung/nutzungsbedingung')
                 .then(rsp => {
                     if (rsp.status === 200 && rsp.data) {
                         this.agbDaten = rsp.data;
                         this.showInfoFenster = true;
                     }

                 })
                 .catch(error => {
                     console.log(error.toJSON());
                 });
            if (this.$store.state.module.retaxWrite) this.getLesebestaetigungWarnung();
        },
        deactivated() {
            this.showUndoUnregisterSuccess = false;
        },

        computed: {

            leseBestaetigungWarnung() {
                return this.$store.state.leseBestaetigungWarnung;
            },
            
      
        },

    methods: {
        initData() { 
            this.showInfoFenster = false;
            this.infoDaten = [{}];
            this.agbDaten = [{}];
            //var t =   typeof (this.$store.state.currentUser.canSwitchIK) !== "undefined" && typeof this.$store.state.currentUser.leistungserbringer === "undefined" ? this.$store.state.currentUser.canSwitchIK : false;
            //console.log('t: ', t);
            this.showSwitchWindow = typeof (this.$store.state.currentUser.canSwitchIK) !== "undefined" && typeof this.$store.state.currentUser.leistungserbringer === "undefined" ? this.$store.state.currentUser.canSwitchIK : false;


        },

        OnShowInfoFenster(value) {
                this.showInfoFenster = value;
               /*if (value) {
                    const index = this.userData.indexOf(this.selectedUser);
                  //  this.selectedUser = item;
                    this.userData.splice(index, 1)
                }
    */
        },
        getLesebestaetigungWarnung() {

            const le = this.$store.state.currentUser.leistungserbringer;
            this.showLeseBestaetigungWarnung = false
            let lBestaetigungWarnung = "";
            this.$http.get('/retax/lesedatumwarnung', {
                params: {
                    Leistungserbringer: le
                }
            }).then((response) => {
                if (response.status === 200 && response.data) {
                    let r = response.data;

                    //prüfen, ob aktuelle Monat in der Liste, der nicht lesebestätigten Beanstandungen ist
                    //wenn ja, dann keine Warnung ausgeben 
                    //let ix = r.findIndex(x => x === this.monat);
                    //if (ix != -1)
                    //    return

                    if (r.length === 1 ) {
                        lBestaetigungWarnung = "Sie haben die Beanstandungen im Monat " + this.monatPretty(r[0]) + " noch nicht gelesen.";
                        this.showLeseBestaetigungWarnung = lBestaetigungWarnung.length > 1 ? true : false;

                    }
                    else if (r.length > 1) {
                        let t = " und ";
                        r.forEach(x => {
                            if (x !== this.monat) {
                                lBestaetigungWarnung += this.monatPretty(x) + t;
                                //console.log('this.LeseBestaetigungWarnung: ', lBestaetigungWarnung);
                            }

                        });
                        lBestaetigungWarnung = "Sie haben die Beanstandungen in den Monaten " + lBestaetigungWarnung.slice(0, -t.length) + " noch nicht gelesen."; //this.LeseBestaetigungWarnung.substr(0, this.LeseBestaetigungWarnung.length - t.length);
                        //console.log('this.LeseBestaetigungWarnung: final ', lBestaetigungWarnung, -t.length);
                        this.showLeseBestaetigungWarnung = lBestaetigungWarnung.length > 1 ? true : false;

                    }
                }
                this.$store.commit('updateLeseBestaetigungWarnung', lBestaetigungWarnung);
            }).catch(error => {
                if (error.response) {
                    this.$store.commit('updateCurrentError', error.response.data);
                }
            });
        },

        monatPretty(monat) {
            let m = monat; //this.monat;
            return String(m).substring(2, String(m).length) + "/20" + String(m).substring(0, String(m).length - 2);
        },

        loginDone() {
            eventBus.$emit('loginSuccessful');
            eventBus.$emit('resetLogoutTimer');
            //this.userMessage = "";
            this.$router.push('/');
        },

    }
    }
</script>